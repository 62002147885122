const appConfig = {
  "name": "pi-dashboard",
  "version": "1.0.0",
  "stage": "release",
  "regions": [
    {
      "name": "us-east-1",
      "isPrimaryRegion": true,
      "deployThisBuild": true,
      "regionDomain": "get.atlas-config-test.mit.edu",
      "amplify": {
        "userPoolId": "us-east-1_nY154N4yV",
        "userPoolWebClientId": "60tv4i4hsnvg4vl3e764r55ibj",
        "oauth": {
          "domain": "atlas-auth-test.mit.edu",
          "scope": [
            "profile",
            "openid",
            "user/all",
            "digital-id/user"
          ],
          "redirectSignIn": "https://pi-dash-test.mit.edu",
          "redirectSignOut": "https://pi-dash-test.mit.edu/logout",
          "responseType": "token",
          "options": {
            "AdvancedSecurityDataCollectionFlag": false
          }
        }
      },
      "signInProvider": "Touchstone",
      "api": {
        "domain": "api-test.mit.edu",
        "paths": {
          "piDashboard": "pi-dash-v1",
          "feature": "feature-v1",
          "digital-id": "digital-id-v1"
        }
      }
    },
    {
      "name": "us-west-1",
      "isPrimaryRegion": false,
      "deployThisBuild": true,
      "regionDomain": "get.atlas-config-test.mit.edu",
      "amplify": {
        "userPoolId": "us-west-1_KRL6VdoBU",
        "userPoolWebClientId": "60tv4i4hsnvg4vl3e764r55ibj",
        "oauth": {
          "domain": "atlas-auth-us-west-test.mit.edu",
          "scope": [
            "profile",
            "openid",
            "user/all",
            "digital-id/user"
          ],
          "redirectSignIn": "https://pi-dash-test.mit.edu",
          "redirectSignOut": "https://pi-dash-test.mit.edu/logout",
          "responseType": "token",
          "options": {
            "AdvancedSecurityDataCollectionFlag": false
          }
        }
      },
      "signInProvider": "Touchstone",
      "api": {
        "domain": "api-us-west-test.mit.edu",
        "paths": {
          "piDashboard": "pi-dash-v1",
          "feature": "feature-v1",
          "digital-id": "digital-id-v1"
        }
      }
    }
  ]
};
export default appConfig;